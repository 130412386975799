import { createApp } from 'vue';
import './index.css';
import './fonts/stylesheet.css';
import './css/bootstrap.css';
import './css/style.css';
import './css/animate.css';
import './css/responsive.css';
import './css/custom.css';
import VueNumber from 'vue-number-animation';
import { createHead } from '@vueuse/head';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';

const app = createApp(App)
  .use()
  .use(router)
  .use(VueNumber)
  .use(createHead())
  .use(VueGtag, {
    config: { id: 'G-X6RZBQQHG8' },
  });

app.config.globalProperties.facebookLink =
  'https://www.facebook.com/ChangaAfrica';
app.config.globalProperties.twitterLink = 'https://twitter.com/m_changa';
app.config.globalProperties.youtubeLink =
  'https://www.youtube.com/channel/UCo8LCOVJmzJb_5fTikHbXeA';
app.config.globalProperties.linkedinLink =
  'https://www.linkedin.com/company/mobi-changa-limited';
app.config.globalProperties.instagramLink =
  'https://www.instagram.com/m_changa_africa/';
app.config.globalProperties.supportPhone = '+254 20 765 0919';
app.config.globalProperties.supportMobilePhone = '+254 711 082 582';
app.config.globalProperties.supportEmail = 'support@mchanga.africa';
app.config.globalProperties.defaultShareImage =
  'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto/v1492603822/commonshare/mchangawide.png';
app.config.globalProperties.mchangaPAYBILL = '891300';
app.config.globalProperties.airtelBusinessName = 'MCHANGA';
app.config.globalProperties.baseURL = process.env.VUE_APP_BASE_URL;
app.config.globalProperties.apiURL = process.env.VUE_APP_ROOT_API;
app.config.globalProperties.portalURL = process.env.VUE_APP_PORTAL_URL;
app.config.globalProperties.allowed_country_codes = [
  '254',
  '256',
  '233',
  '221',
  '226',
  '227',
  '234',
  '255',
  '225',
  '260',
  '228',
  '241',
  '229',
];
app.config.globalProperties.available_countries = [
  'KE',
  'UG',
  'GH',
  'SN',
  'BF',
  'NE',
  'NG',
  'TZ',
  'CI',
  'ZM',
  'TG',
  'GA',
  'BJ',
];
app.config.globalProperties.local_country_codes = {
  SN: '221',
  BF: '226',
  NE: '227',
  NG: '234',
  TZ: '255',
  CI: '225',
  UG: '256',
  GH: '233',
  ZM: '260',
  TG: '228',
  GA: '241',
  BJ: '229',
};
app.config.globalProperties.email_key =
  '546TCFGH4D6F8T7Y8DR64_45DFG5DF7@_GDRRRRTD64FGDF54GH8D6';
app.config.globalProperties.flutter_currency_codes = [
  'GHS',
  'UGX',
  'NGN',
  'XOF',
  'TZS',
  'ZMW',
  'XAF',
];
app.config.globalProperties.flutter_currency_min = {
  GHS: 10,
  UGX: 7500,
  NGN: 500,
  XOF: 100,
  TZS: 3000,
  ZMW: 10,
  XAF: 100,
};
app.config.globalProperties.xaf_msisdn = '+221785976920';
app.config.globalProperties.country_flag = {
  KE: 'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1664437758/web/kenya.png',
  SN: 'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1664437758/web/senegal.png',
  BF: 'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1664437758/web/burkina.png',
  NE: 'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1664437758/web/niger.png',
  GH: 'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1664437758/web/ghana.png',
  NG: 'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1664437758/web/nigeria.png',
  UG: 'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1664452350/web/uganda.png',
  TZ: 'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1698326762/web/tanzania1.png',
  CI: 'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1693203168/OTHERS_LOGOS/CI.png',
  ZM: 'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1698156273/web/zm.png',
  TG: 'https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1698387458/web/togo.png',
  BJ: 'https://res.cloudinary.com/changa/image/upload/c_thumb, f_auto, q_auto: low, w_50/v1698387458/web/benin.png',
  GA: 'https://res.cloudinary.com/changa/image/upload/c_thumb, f_auto, q_auto: low, w_50/v1698387726/web/gabon.png',
};
app.config.globalProperties.ipay_currency_codes = ['TZS'];
app.config.globalProperties.ipay_currency_min = {
  TZS: 1000,
};
app.config.globalProperties.ipay_vids = {
  TZS: process.env.VUE_APP_IPAY_TZ_VID,
};
app.config.globalProperties.XOF_Countries = ['CI', 'NE', 'SN', 'BF', 'BJ'];
app.config.globalProperties.XAF_Countries = ['TG', 'GA'];

app.mixin({
  data() {
    return {};
  },
  methods: {
    theFormat(number) {
      return number.toLocaleString('en', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
  },
});

app.mount('#app');
